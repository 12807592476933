import React from "react"
import Button from "./Button"
import imgPlace from "../../images/dna.jpg"

function CommunityDriven({ title, description, img, buttonLink, white, position, orangeC, buttonText }) {
  return (
    <div className="paddingTop30">
      <div className="gridTwo hide">
        <div>
          <h2 className="sectionTitle" style={white ? {color: "white"} : null}>
            {/* we are community driven */}
            {title}
          </h2>
          <p className="textP" style={white ? {color: "white"} : null}>
            {/* From its creation, xHub has been a thriving member of the developers
            and the digital community.
            <br /> Sharing knowledge is a pillar of our DNA. */}
            {description}
          </p>
          {buttonLink ? (<Button link={buttonLink} position={position} orange={orangeC ? orangeC : null} >{buttonText}</Button>) : null}
        </div>
        <div>
          <img
            src={img}
            alt="Hero Picture"
            className="centeredImage80 peopleFirst"
          />
        </div>
      </div>

      <div className="gridTwo show">
        <div>
          <img
            src={img}
            alt="Hero Picture"
            className="centeredImage80 peopleFirst"
          />
        </div>
        <div>
          <h2 className="sectionTitle" style={white ? {color: "white"} : null}>{title}</h2>
          <p className="textP" style={white ? {color: "white"} : null}>{description}</p>
          {buttonLink ? (<Button link={buttonLink} position="center" orange={orangeC ? orangeC : null}>{buttonText}</Button>) : null}
        </div>
      </div>
    </div>
  )
}

export default CommunityDriven
