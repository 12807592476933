import React from "react"
import styled from "styled-components"
import { CubeAnimate } from "../styles/keyframes"

const CubeItem = styled.span`
  position: absolute;
  z-index: 3;
  width: 80px;
  height: 80px;
  background: #f7942e;
  display:none;
  &:nth-child(2n) {
    border-radius: 50%;
    display:block;
  }

  &:nth-child(3n + 1) {
    background: transparent;
    border: 5px solid #f7942e;
  }

  &:nth-child(1) {
    top: 50%;
    left: 20%;
    animation: ${CubeAnimate} 10s linear infinite;
  }
  &:nth-child(2) {
    top: 80%;
    left: 40%;
    animation: ${CubeAnimate} 12s linear infinite;
  }
  &:nth-child(3) {
    top: 10%;
    left: 65%;
    animation: ${CubeAnimate} 15s linear infinite;
  }
  &:nth-child(4) {
    top: 50%;
    left: 70%;
    animation: ${CubeAnimate} 6s linear infinite;
    // border-color: ${props => props.theme.primaryColor};
  }
  &:nth-child(5) {
    top: 10%;
    left: 30%;
    animation: ${CubeAnimate} 9s linear infinite;
  }
  &:nth-child(6) {
    top: 90%;
    left: 84%;
    animation: ${CubeAnimate} 8s linear infinite;
  }
  &:nth-child(7) {
    top: 80%;
    left: 5%;
    animation: ${CubeAnimate} 5s linear infinite;
  }
  &:nth-child(8) {
    top: 35%;
    left: 50%;
    animation: ${CubeAnimate} 14s linear infinite;
  }
  &:nth-child(9) {
    top: 5%;
    left: 5%;
    animation: ${CubeAnimate} 11s linear infinite;
  }
  &:nth-child(10) {
    top: 25%;
    left: 90%;
    animation: ${CubeAnimate} 10s linear infinite;
  }
`

const Cubes = styled.div`
  width: 100%;
`

export default () => (
  <Cubes>
    <CubeItem />
    <CubeItem />
    <CubeItem />
    <CubeItem />
    <CubeItem />
    <CubeItem />
    <CubeItem />
    <CubeItem />
    <CubeItem />
    <CubeItem />
  </Cubes>
)
