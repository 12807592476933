import React, { Fragment } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  BtnContainer,
  DownloadBtn,
  DownloadTitle,
  SectionContainer,
} from "../styles/prez"
import { Banner, Container } from "../styles/common"
import downloadFileVersionEng from "../pdf/XHUB-2022-ENGLISH.pdf"
import downloadFileVersionFR from "../pdf/XHUB-2022-FRANCAISE.pdf"
import { HomeBanner } from "../styles/home"
import HomeCube from "../components/homeCube"
import CommunityDriven from "../components/Components/CommunityDriven"
import imgPlace from "../images/DownloadFile.png"
import Button from "../components/Components/Button"

const data = [
  {
    title: "Download in English 🇺🇸",
    fileName: downloadFileVersionEng,
    reverse: true,
  },
  {
    title: "Download in French 🇫🇷",
    fileName: downloadFileVersionFR,
    reverse: false,
  },
]
const DownloadPresentationPage = () => (
  <Layout>
    <Fragment>
      <SEO
        title="Download-Prez | x-hub.io"
        keywords={[
          "xhub",
          "devoxx",
          "devoxxmorocco",
          "devoxx4kids",
          "morocco",
          "casablanca",
          "x-hub.io",
          "devc",
          "Application & Technologies",
        ]}
      />
      {/* <Banner download overlay>
            <SectionContainer>
                <DownloadTitle>. Our Presentation</DownloadTitle>
                <BtnContainer>
                    {data.map((elem)=>
                    <DownloadBtn href={elem.fileName} download reverse={elem.reverse} >
                    <span />
                    <span />
                    <span />
                    <span />
                    {elem.title}
                    </DownloadBtn>
                    )}
                </BtnContainer>
            </SectionContainer>
        </Banner> */}
      <HomeBanner>
        <Container flex spaceBetween flexCenter>
          {/* <TextContainer>
            <ScrollAnimation
              animateOnce={true}
              delay={300}
              duration={0.5}
              animateIn="bounceInLeft"
              animateOut="bounceOutRight"
            >
              <Title>{data.site.siteMetadata.title}</Title>
            </ScrollAnimation>
            <ScrollAnimation
              animateOnce={true}
              delay={600}
              duration={0.6}
              animateIn="bounceInLeft"
              animateOut="bounceOutRight"
            >
              <Description>
                 Probably the best digital accelerator in problem solving & tech excellence with DevOps & Agile styles of work
              </Description>
              <OutLineBtn to="/prez">Our brochure</OutLineBtn>
            </ScrollAnimation>
          </TextContainer> */}
          <div className="paddingtop30">
            <div className="gridTwo hide">
              <div>
                <h2 className="sectionTitle" style={{color: "#fff"}}>
                  {/* we are community driven */}
                  Our presentation
                </h2>
                <p className="textP" style={{color: "#fff"}}>
                  {/* From its creation, xHub has been a thriving member of the developers
            and the digital community.
            <br /> Sharing knowledge is a pillar of our DNA. */}
                  Download our presentation or see it online, this text must be
                  changed
                </p>
                <div className="gridTwo" style={{justifyItems: "flex-start"}}>
                  {data.map(elem => (
                    <Button orange link={elem.fileName}>{elem.title}</Button>
                  ))}
                </div>
              </div>
              <div>
                <img
                  src={imgPlace}
                  alt="Hero Picture"
                  className="centeredImage80 peopleFirst"
                />
              </div>
            </div>

            <div className="gridTwo show">
              <div>
                <img
                  src={imgPlace}
                  alt="Hero Picture"
                  className="centeredImage80 peopleFirst"
                />
              </div>
              <div>
                <h2 className="sectionTitle" style={{color: "#fff"}}>our presentation</h2>
                <p className="textP" style={{color: "#fff"}}>
                  Download our presentation or see it online, this text must be
                  changed
                </p>
                <div className="gridTwo">
                  {data.map(elem => (
                    <Button orange link={elem.fileName}>{elem.title}</Button>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/* <IntoImg> */}

          {/* <FullImg src={homeBackground} alt="home banner" /> */}
          {/* <Slider> */}
          {/* <img alt="slid1" src={imgPlace} /> */}
          {/* <img alt="slide2" src={imgPlace} />
              <img alt="slide3" src={imgPlace} /> */}
          {/* </Slider> */}
          {/* </IntoImg> */}
        </Container>
        <HomeCube />
      </HomeBanner>
    </Fragment>
  </Layout>
)

export default DownloadPresentationPage
