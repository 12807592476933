import React from "react"

function Button({link, children, position, orange}) {
  return (
    <div>
        <div style={{ marginTop: "20px", textAlign: position }}>
      <a
        href={link}
        className="buttonStyle"
      >
          <div
            className="buttonLayout"
            style={orange ? {backgroundColor: "#F7942E"} : null}
          >
            {children}
          </div>
      </a>
        </div>
    </div>
  )
}

export default Button
